import alterpressApi from './base';

import { IProduct } from '../../models/product.model';

export const productApi = alterpressApi
    .enhanceEndpoints({
        addTagTypes: ['PRODUCT'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getProduct: builder.query<IProduct, number | string | undefined | null>({
                query: (productId) => `products/${productId}?expand=variants`,
                providesTags: ['PRODUCT'],
            }),
            getProducts: builder.query<{ items: IProduct[] }, number | string | undefined | null>({
                query: () => `products?expand=variants`,
                providesTags: ['PRODUCT'],
            }),
        }),
    });

export const { useGetProductQuery, useGetProductsQuery } = productApi;
