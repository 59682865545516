import * as Yup from 'yup';

import IFormField from '../models/form-field.model';
import { ILocationAddress } from '../models/address.model';
import { IClient } from '../models/client.model';
import { getAddressInitialValues, getAddressValidationSchema } from './address.form';
import useTranslations from '../hooks/use-translations';

export interface IUserOrderValues {
    taxNumber?: string;
    companyName?: string;
    address: ILocationAddress;
}

export function getClientOrderInitialValues(client?: IClient | null): IUserOrderValues {
    return {
        taxNumber: client?.taxNumber || '',
        companyName: client?.name || '',
        ...(client
            ? getAddressInitialValues({ initialAddress: { ...client.address } })
            : getAddressInitialValues()),
    };
}

export function getCandidateOrderInitialValues(): IUserOrderValues {
    return {
        ...getAddressInitialValues(),
    };
}

export function getCandidateOrderValidationSchema(
    tInputAddress: ReturnType<typeof useTranslations<'InputAddress'>>
) {
    return Yup.object({
        ...getAddressValidationSchema(tInputAddress),
    });
}

export function getClientOrderValidationSchema(
    t: ReturnType<typeof useTranslations<'UserOrder'>>,
    tInputAddress: ReturnType<typeof useTranslations<'InputAddress'>>
) {
    return Yup.object({
        taxNumber: Yup.string().required(t.form.taxNumber.error.required),
        companyName: Yup.string().required(t.form.companyName.error.required),
        ...getAddressValidationSchema(tInputAddress),
    });
}

export function getCandidateOrderFields(): IFormField[] {
    return [
        {
            name: '',
            type: 'input-address',
            includeLocation: false,
        },
    ];
}

export function getClientOrderFields(
    t: ReturnType<typeof useTranslations<'UserOrder'>>
): IFormField[] {
    return [
        {
            name: 'taxNumber',
            type: 'tel',
            placeholder: t.form.taxNumber.placeholder,
            isRequired: true,
        },
        {
            name: 'companyName',
            type: 'text',
            placeholder: t.form.companyName.placeholder,
            isRequired: true,
        },
        {
            name: '',
            type: 'input-address',
            includeLocation: false,
        },
    ];
}
