import React from 'react';
import { Formik, Form, FormikConfig } from 'formik';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';
import { BaseImage } from '@alterpage/gatsby-plugin-image';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import {
    container,
    grid,
    sectionBox,
    radiusBox,
    separator,
    title,
    boxTitle,
    summaryBox,
    button,
    fields,
    infoBox,
    loader,
    fetchErrorText,
    summaryItem,
    summaryItemValue,
    errorText,
    loading,
} from './user-order.module.scss';
import { ISection } from '../../models/section.model';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IPage } from '../../models/page.model';
import { ICreateOrderMutation } from '../../models/order.model';
import useTranslations from '../../hooks/use-translations';
import { getLessImportantHeading } from '../../utils/get-less-important-heading';
import {
    getClientOrderFields,
    getClientOrderInitialValues,
    getClientOrderValidationSchema,
    getCandidateOrderInitialValues,
    getCandidateOrderValidationSchema,
    getCandidateOrderFields,
    IUserOrderValues,
} from '../../formik/user-order-form';
import { useProduct } from '../../hooks/use-product';
import { useClient } from '../../hooks/use-client';
import { useOrder } from '../../hooks/use-order';
import useRtkQueryFormError from '../../hooks/use-rtk-query-form-error';
import { getUrlParamValue } from '../../utils/get-url-param-value';
import { useClientPermission } from '../../hooks/use-client-permission';
import { usePagePathname } from '../../hooks/use-page-pathname';

import Section from '../hoc/section';
import RadiusBox from '../hoc/radius-box';
import DashboardHeader from '../molecules/dashboard-header';
import Button from '../atoms/button';
import FieldGenerator from '../molecules/field-generator';
import Loader from '../atoms/loader';
import NoPermissionInfo from '../organisms/no-permission-info';

export interface IUserOrderProps {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
    type: 'client' | 'candidate';
}

interface IUserOrderQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'pathname' | 'locale'>>;
    paymentImage: ImageDataLike | null;
}

const CLIENT_PLAN_BUSINESS_MEANING = process.env.CLIENT_PLAN_BUSINESS_MEANING;
const CANDIDATE_TEST_BUSINESS_MEANING = process.env.CANDIDATE_TEST_BUSINESS_MEANING;

const UserOrder: React.FC<IUserOrderProps> = ({
    className = '',
    section,
    TitleTag = 'h2',
    type,
}) => {
    const { locale } = usePageContext();
    const { allPage, paymentImage } = useStaticQuery<IUserOrderQueryResult>(query);
    const pricingPagePathname = usePagePathname(allPage);
    const variantId = getUrlParamValue('variantId');
    const t = useTranslations('UserOrder');
    const tInputAddress = useTranslations('InputAddress');
    const { style, css, sectionId } = section;
    const product = useProduct({
        queries: ['list'],
    });

    const client = useClient();
    const canShop = useClientPermission(['can-shop']);
    const order = useOrder();
    const { formikRef, globalErrorMessage } = useRtkQueryFormError(order.create.errors);

    const isLoading = product.list.isLoading;
    const isSubmitLoading = order.create.isLoading;

    const businessMeaning =
        type === 'client' ? CLIENT_PLAN_BUSINESS_MEANING : CANDIDATE_TEST_BUSINESS_MEANING;
    const products = product.list.data?.items;
    const singleProduct = products?.find(
        (product) => product.businessMeaning.toString() === businessMeaning
    );
    const variant =
        type === 'client'
            ? singleProduct?.variants?.find((variant) => variant.variantId.toString() === variantId)
            : singleProduct?.variants?.[0];

    const BoxTitleTag = getLessImportantHeading(TitleTag) || 'p';

    const initialValues =
        type === 'client'
            ? getClientOrderInitialValues(client.data)
            : getCandidateOrderInitialValues();
    const validationSchema =
        type === 'client'
            ? getClientOrderValidationSchema(t, tInputAddress)
            : getCandidateOrderValidationSchema(tInputAddress);
    const formFields = type === 'client' ? getClientOrderFields(t) : getCandidateOrderFields();

    const handleSubmit: FormikConfig<IUserOrderValues>['onSubmit'] = (values) => {
        if (!variant) return;
        const mutation: ICreateOrderMutation = {
            address: {
                ...values.address,
                ...(type === 'client'
                    ? {
                          companyName: values.companyName,
                          taxNumber: values.taxNumber,
                      }
                    : {}),
            },
            items: [{ variantId: variant.variantId, quantity: 1 }],
        };
        localStorage.setItem('orderLanguage', locale);
        order.create.fetch(mutation);
    };

    if (type === 'client' && !canShop) {
        return <NoPermissionInfo isFullHeight={true} reason="role" />;
    }

    if (!singleProduct || isLoading) {
        return (
            <div className={infoBox}>
                <Loader className={loader} />
            </div>
        );
    }

    if (product.single.isError) {
        return (
            <div className={infoBox}>
                <p className={fetchErrorText}>{t.fetchError}</p>
            </div>
        );
    }

    if (!variant) {
        if (type === 'client') {
            navigate(pricingPagePathname || '/', { replace: true });
            return null;
        }
        return (
            <div className={infoBox}>
                <p className={fetchErrorText}>{t.fetchError}</p>
            </div>
        );
    }

    return (
        <Section
            className={`${sectionBox} ${className}`}
            classes={{ container: grid }}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {() => (
                    <Form className={`${container} ${isSubmitLoading ? loading : ''}`}>
                        <DashboardHeader
                            TitleTag={TitleTag}
                            className={title}
                            hint={
                                type === 'client'
                                    ? t.hintClient(pricingPagePathname)
                                    : t.hintCandidate
                            }
                        >
                            {t.title} <span>{variant.name}</span>
                        </DashboardHeader>
                        <RadiusBox className={radiusBox}>
                            <BoxTitleTag className={boxTitle}>{t.form.title}</BoxTitleTag>
                            <span className={separator} />
                            <div className={fields}>
                                {formFields.map((field) => {
                                    return (
                                        <FieldGenerator
                                            key={`client-order-field-${field.name}-${field.type}`}
                                            field={field}
                                        />
                                    );
                                })}
                            </div>
                        </RadiusBox>
                        <div className={summaryBox}>
                            <RadiusBox className={radiusBox}>
                                <BoxTitleTag className={boxTitle}>{t.summary.title}</BoxTitleTag>
                                <span className={separator} />
                                <div className={summaryItem}>
                                    <p>{variant.name}</p>
                                </div>
                                <span className={separator} />
                                <div className={summaryItem}>
                                    <p>{t.summary.pay}</p>
                                    <p className={summaryItemValue}>
                                        {variant.finalPrice.grossDisplay}
                                    </p>
                                </div>
                                {paymentImage && (
                                    <>
                                        <span className={separator} />
                                        <BaseImage image={getImage(paymentImage)} />
                                    </>
                                )}
                            </RadiusBox>
                            {globalErrorMessage && (
                                <p className={errorText}>{globalErrorMessage}</p>
                            )}
                            <Button className={button} type="submit" isLoading={isSubmitLoading}>
                                {t.submit}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Section>
    );
};

const query = graphql`
    query {
        allPage(filter: { type: { eq: "pricing" } }) {
            edges {
                node {
                    locale
                    pathname
                }
            }
        }
        paymentImage: file(relativePath: { eq: "payment-methods.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: NONE, quality: 95)
            }
        }
    }
`;

export default UserOrder;
