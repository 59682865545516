import { useMemo } from 'react';

import {
    useCreateOrderMutation,
    useGetOrderQuery,
    useGetOrdersQuery,
    useRetryPaymentMutation,
} from '../redux/api/order';
import { getRtkQueryErrors } from '../utils/get-rtk-query-errors';
import useTranslations from './use-translations';
import { getUrlParamValue } from '../utils/get-url-param-value';

export type TUseOrderQueries = 'single' | 'list' | 'delivery' | 'payment';

interface IUseOrderConfig {
    queries?: TUseOrderQueries[];
    fixedKeys?: { create?: string; validate?: string; retryPayment?: string };
}

export const useOrder = ({ queries = [], fixedKeys }: IUseOrderConfig | undefined = {}) => {
    const t = useTranslations('RTKQueryApi');
    const orderUUID = getUrlParamValue('id');

    const skipOrderQuery = !queries.includes('single') || !orderUUID;
    const skipOrdersQuery = !queries.includes('list');
    const getOrderQuery = useGetOrderQuery(orderUUID, { skip: skipOrderQuery });
    const getOrdersQuery = useGetOrdersQuery(undefined, { skip: skipOrdersQuery });

    const createConfig = fixedKeys?.create ? { fixedCacheKey: fixedKeys.create } : {};
    const retryPaymentConfig = fixedKeys?.retryPayment
        ? { fixedCacheKey: fixedKeys.retryPayment }
        : {};

    const [create, createOrderMutation] = useCreateOrderMutation(createConfig);
    const [retryPayment, retryPaymentMutation] = useRetryPaymentMutation(retryPaymentConfig);

    const singleErrors = useMemo(() => {
        return getRtkQueryErrors(getOrderQuery.error, t.errors);
    }, [getOrderQuery.error, t.errors]);
    const listErrors = useMemo(() => {
        return getRtkQueryErrors(getOrdersQuery.error, t.errors);
    }, [getOrdersQuery.error, t.errors]);
    const createErrors = useMemo(() => {
        return getRtkQueryErrors(createOrderMutation.error, t.errors);
    }, [createOrderMutation.error, t.errors]);
    const retryPaymentErrors = useMemo(() => {
        return getRtkQueryErrors(retryPaymentMutation.error, t.errors);
    }, [retryPaymentMutation.error, t.errors]);

    return {
        single: {
            data: getOrderQuery.data,
            isFetching: getOrderQuery.isFetching,
            isLoading: getOrderQuery.isLoading,
            isSuccess: getOrderQuery.isSuccess,
            isError: getOrderQuery.isError,
            errors: singleErrors,
        },
        list: {
            data: getOrdersQuery.data,
            isFetching: getOrdersQuery.isFetching,
            isLoading: getOrdersQuery.isLoading,
            isSuccess: getOrdersQuery.isSuccess,
            isError: getOrdersQuery.isError,
            errors: listErrors,
        },
        create: {
            fetch: create,
            data: createOrderMutation.data,
            isLoading: createOrderMutation.isLoading,
            isSuccess: createOrderMutation.isSuccess,
            isError: createOrderMutation.isError,
            errors: createErrors,
        },
        retryPayment: {
            fetch: retryPayment,
            data: retryPaymentMutation.data,
            isLoading: retryPaymentMutation.isLoading,
            isSuccess: retryPaymentMutation.isSuccess,
            isError: retryPaymentMutation.isError,
            errors: retryPaymentErrors,
        },
    };
};
