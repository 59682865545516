import { useMemo } from 'react';

import { getRtkQueryErrors } from '../utils/get-rtk-query-errors';
import useTranslations from './use-translations';
import { useGetProductQuery, useGetProductsQuery } from '../redux/api/product';

export type TUseProductQueries = 'single' | 'list';

interface IUseProductConfig {
    queries?: TUseProductQueries[];
    productId?: number | string | null;
}

export const useProduct = ({ queries = [], productId }: IUseProductConfig | undefined = {}) => {
    const t = useTranslations('RTKQueryApi');

    const skipProductQuery = !queries.includes('single') || !productId;
    const skipProductsQuery = !queries.includes('list');

    const getProductQuery = useGetProductQuery(productId, { skip: skipProductQuery });
    const getProductsQuery = useGetProductsQuery(undefined, { skip: skipProductsQuery });

    const singleErrors = useMemo(() => {
        return getRtkQueryErrors(getProductQuery.error, t.errors);
    }, [getProductQuery.error, t.errors]);
    const listErrors = useMemo(() => {
        return getRtkQueryErrors(getProductsQuery.error, t.errors);
    }, [getProductsQuery.error, t.errors]);

    return {
        single: {
            data: getProductQuery.data,
            isFetching: getProductQuery.isFetching,
            isLoading: getProductQuery.isLoading,
            isSuccess: getProductQuery.isSuccess,
            isError: getProductQuery.isError,
            errors: singleErrors,
        },
        list: {
            data: getProductsQuery.data,
            isFetching: getProductsQuery.isFetching,
            isLoading: getProductsQuery.isLoading,
            isSuccess: getProductsQuery.isSuccess,
            isError: getProductsQuery.isError,
            errors: listErrors,
        },
    };
};
