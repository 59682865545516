// extracted by mini-css-extract-plugin
export var backgroundAnimation = "user-order-module--backgroundAnimation--f61ac";
export var boxTitle = "user-order-module--box-title--8878b";
export var button = "user-order-module--button--06f18";
export var container = "user-order-module--container--f16bb";
export var errorText = "user-order-module--error-text--ca2e6";
export var fetchErrorText = "user-order-module--fetch-error-text--edae0";
export var fields = "user-order-module--fields--14e64";
export var grid = "user-order-module--grid--da950";
export var infoBox = "user-order-module--info-box--07a4a";
export var loader = "user-order-module--loader--d7920";
export var loading = "user-order-module--loading--2e936";
export var radiusBox = "user-order-module--radius-box--9af08";
export var sectionBox = "user-order-module--section-box--fc174";
export var separator = "user-order-module--separator--8ea5c";
export var summaryBox = "user-order-module--summary-box--b65a0";
export var summaryItem = "user-order-module--summary-item--768f9";
export var summaryItemValue = "user-order-module--summary-item-value--f88ee";
export var title = "user-order-module--title--ce21d";